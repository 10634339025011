import http from "./api.js";
import errorHandler from "@/utils/errorHandler.js";

class AuthService {
  async login(user) {
    return http.post(`/auth/login`, user);
  }

  async resetPassword(token, password) {
    return http.post("/auth/resetPassword?token=" + token, {
      password: password,
    });
  }
  async sendResetPasswordMail(email) {
    return http
      .post("/auth/sendResetPasswordMail", { email: email })
      .then((response) => {
        errorHandler.handleSuccess(response);
        return true;
      })
      .catch((e) => {
        errorHandler.handleError(e);
        return false;
      });
  }
  async inviteUser(invites) {
    return http.post("/auth/inviteUser", invites);
  }
  async registerConfirm(token, registerObject) {
    return http.post("/auth/registerConfirm?token=" + token, registerObject);
  }
}

export default new AuthService();

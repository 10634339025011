import { createApp } from "vue";

import App from "./App.vue";
import router from "./plugins/router";
import VueLogger from "vuejs3-logger";
import "vuetify/dist/vuetify.min.css";

import "./assets/style/main.scss";
import "./index.css";
import "@mdi/font/css/materialdesignicons.css";

import store from "./store";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import setupInterceptors from "./helpers/base.interceptor";

import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    dark: true,
  },
});

const loggerOptions = {
  isEnabled: true,
  logLevel: /* isProduction ? "error" : */ "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};
setupInterceptors(store);

createApp(App)
  .use(router)
  .use(vuetify)
  .use(store)
  .use(VueLogger, loggerOptions)
  .component("EasyDataTable", Vue3EasyDataTable)
  .mount("#app");

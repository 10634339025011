<template>
  <div class="toast" :class="{ show: show }">
    {{ message }}
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "ToastResponse",
  props: {
    message: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      default: 5000,
    },
  },
  setup(props) {
    const show = ref(false);

    const showToast = () => {
      show.value = true;
      setTimeout(() => {
        show.value = false;
      }, props.duration);
    };

    return {
      show,
      showToast,
    };
  },
};
</script>

<style lang="scss">
.toast {
  position: fixed;
  right: 30px;
  padding: 20px 50px;
  background-color: #333;
  top: 170px;
  color: #fff;
  border-radius: 4px;
  opacity: 0;
  font-size: 44px;
  transition: all 0.6s ease-in-out;
  transform: translateX(150%);
  z-index: 9999;

  &.show {
    transition: all 0.6s ease-in-out;
    opacity: 1;
    transform: translateX(0);
  }
}
</style>

import http from "./api.js";

import errorHandler from "@/utils/errorHandler";

class ShiftService {
  buildQueryFromFilter(filter) {
    let filterQuery = [];

    if (typeof filter.type !== "undefined") {
      filterQuery.push("type=" + filter.type);
    }
    if (typeof filter.occupation !== "undefined") {
      filterQuery.push("occupation=" + filter.occupation);
    }
    if (typeof filter.locationId !== "undefined") {
      filterQuery.push("locationId=" + filter.locationId);
    }
    if (typeof filter.userId !== "undefined") {
      filterQuery.push("userId=" + filter.userId);
    }
    if (typeof filter.completed !== "undefined") {
      filterQuery.push("completed=" + filter.completed);
    }
    if (typeof filter.group !== "undefined") {
      filterQuery.push("group=" + filter.group);
    } else {
      filterQuery.push("group=false");
    }
    return filterQuery.join("&&");
  }

  getShift(shiftId) {
    return http.get("/shift/" + shiftId);
  }
  getSummary() {
    return http.get("/shifts/summary");
  }

  async getShifts(filter) {
    if (typeof filter === "undefined") {
      return http.get(`/shifts`).catch((e) => {
        errorHandler.handleError(e);
        return [];
      });
    }
    return http
      .get("/shifts?" + this.buildQueryFromFilter(filter))
      .catch((e) => {
        errorHandler.handleError(e);
        return [];
      });
  }

  getUpcoming(filter) {
    if (typeof filter === "undefined") {
      return http.get(`/shifts/upcoming`);
    }
    return http.get("/shifts/upcoming?" + this.buildQueryFromFilter(filter));
  }

  async setUser(shiftId, userId) {
    return http
      .post(`/shift/${shiftId}/set?userId=${userId}`)
      .then((response) => {
        errorHandler.handleSuccess(response);
        return true;
      })
      .catch((e) => {
        errorHandler.handleError(e);
        return false;
      });
  }
  async removeUser(shiftId, userId) {
    return http
      .post(`/shift/${shiftId}/remove?userId=${userId}`)
      .then((response) => {
        errorHandler.handleSuccess(response);
        return true;
      })
      .catch((e) => {
        errorHandler.handleError(e);
        return false;
      });
  }

  async swap(swapFromShift, swapToShift) {
    return http
      .post(`/shift/swap?swapFrom=${swapFromShift}&&swapTo=${swapToShift}`)
      .then(() => {
        return true;
      })
      .catch((e) => {
        errorHandler.handleError(e);
        return false;
      });
  }

  async userShowedUp(shiftId, userShowedUp) {
    return http
      .post(`shift/${shiftId}/userShowedUp?value=${userShowedUp}`)
      .then(() => {
        return true;
      })
      .catch((e) => {
        errorHandler.handleError(e);
        return false;
      });
  }
  async createShift(shiftBlob) {
    return http
      .put("/shift", shiftBlob)
      .then((response) => {
        errorHandler.handleSuccess(response);
        return true;
      })
      .catch((e) => {
        errorHandler.handleError(e);
        return false;
      });
  }

  async deleteShift(shiftId) {
    return http
      .delete("/shift/" + shiftId)
      .then((response) => {
        errorHandler.handleSuccess(response);
        return true;
      })
      .catch((e) => {
        errorHandler.handleError(e);
        return false;
      });
  }
  async updateShift(shift) {
    return http
      .patch("/shift/" + shift.id, shift)
      .then((response) => {
        errorHandler.handleSuccess(response);
        return true;
      })
      .catch((e) => {
        errorHandler.handleError(e);
        return false;
      });
  }
  importShifts(data) {
    return http.post("/shifts/import", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new ShiftService();

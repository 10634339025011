import axios from "../services/api";
import LocalStorageService from "../services/localStorage.service";
import EventBus from "./event.bus";

let isRefreshing = false;
let refreshSubscribers = [];

const setup = (store) => {
  axios.interceptors.request.use(
    (config) => {
      const token = LocalStorageService.getLocalAccessToken();
      if (token) {
        config.headers["x-access-token"] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (error) => {
      const originalRequest = error.config;
      try {
        error.response;
      } catch (e) {
        return Promise.reject(error);
      }
      try {
        error.response.status;
      } catch (e) {
        return Promise.reject(error);
      }
      if (error.response.status === 401 && !originalRequest._retry) {
        // if unauthorized try to get refresh token
        if (isRefreshing) {
          try {
            const token = await new Promise((resolve) => {
              refreshSubscribers.push((newToken) => {
                resolve(newToken);
              });
            });
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          } catch (err) {
            return Promise.reject(err);
          }
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const refreshResponse = await axios.post("/auth/refreshToken", {
            refreshToken: LocalStorageService.getLocalRefreshToken(),
          });

          const userTokenData = refreshResponse.data.data.user;
          store.dispatch("auth/refreshToken", userTokenData);
          LocalStorageService.setUser(userTokenData);
          originalRequest.headers.Authorization = `Bearer ${userTokenData.accessToken}`;

          refreshSubscribers.forEach((callback) =>
            callback(userTokenData.accessToken)
          );
          refreshSubscribers = [];
          return axios(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }
      if (error.response.status === 401) {
        EventBus.dispatch("logout");
      }

      return Promise.reject(error);
    }
  );
};

export default setup;

export const roles = {
  helperGuest: "helper.guest",
  helperTicket: "helper.ticket",
  helperCrew: "helper.crew",
  coordinator: "coordinator",
  admin: "admin",
};
export const roleArray = [
  roles.helperGuest,
  roles.helperTicket,
  roles.helperCrew,
  roles.coordinator,
  roles.admin,
];
export const roleColors = ["red", "deep-purple", "green", "orange", "yellow"];

export function isAllowedForRoute(routeIsAllowedFor, userRole) {
  let routeIndex = roleArray.indexOf(routeIsAllowedFor);
  let roleIndex = roleArray.indexOf(userRole);
  return roleIndex >= routeIndex;
}

export function toSimpleRoles(roles) {
  return roles.map((item) => {
    return item.name;
  });
}

export function colorByRole(role) {
  return roleColors[roleArray.indexOf(role)];
}

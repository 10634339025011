import axios from "axios";

export default axios.create({
  baseURL:
    process.env.VUE_APP_API_URL ||
    "https://schwarmplaner.zugvoegelfestival.org/api",
  headers: {
    "Content-type": "application/json",
  },
});

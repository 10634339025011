import { createRouter, createWebHistory } from "vue-router";
import EventBus from "../helpers/event.bus";
import store from "../store/index";
import { isAllowedForRoute, roles } from "@/helpers/role.helper";

const routes = [
  {
    name: "Start",
    path: "",
    meta: { role: "public" },
    component: () => import("../views/login/Index"),
  },
  {
    name: "user",
    path: "/user",
    meta: {
      role: "public",
    },
    component: () => import("../views/layouts/UserLayout.vue"),
    children: [
      {
        name: "dashboard",
        path: "dashboard",
        meta: { role: roles.helperGuest, title: "Übersicht" },
        component: () => import("../views/dashboard/Index.vue"),
      },
      {
        path: "take",
        name: "take",
        meta: { role: roles.helperGuest, title: "Schichten belegen" },
        component: () => import("../views/shifts/take/Index.vue"),
      },
      {
        path: "swap",
        name: "swap",
        meta: { role: roles.helperGuest, title: "Schichten tauschen" },
        component: () => import("../views/shifts/swap/Index.vue"),
      },
      {
        path: "shifts",
        name: "shifts",
        meta: { role: roles.admin, title: "Schichten verwalten" },
        component: () => import("../views/shifts/import/Index.vue"),
      },
      {
        path: "helpers",
        name: "helpers",
        meta: { role: roles.admin, title: "Helfeverwalten" },
        component: () => import("../views/helpers/Index.vue"),
      },
      {
        path: "locations",
        name: "locations",
        meta: { role: roles.admin, title: "Arbeitsbereiche verwalten" },
        component: () => import("../views/locations/manage/Index.vue"),
      },
      {
        path: "settings",
        name: "settings",
        meta: { role: roles.admin, title: "Einstellungen" },
        component: () => import("../views/settings/Index.vue"),
      },
    ],
  },
  {
    path: "/locations",
    meta: { role: "public" },
    component: () => import("../views/layouts/LocationsLayout.vue"),
    children: [
      {
        path: "rotator",
        name: "rotator",
        meta: {
          role: "public",
        },
        component: () => import("../views/locations/rotator/Index.vue"),
      },
      {
        path: "static",
        name: "static",
        meta: { role: "public" },
        component: () => import("../views/locations/static/Index.vue"),
      },
    ],
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    meta: { role: "public", title: "Passwort zurücksetzen" },
    component: () => import("../views/reset/Index.vue"),
  },
  {
    path: "/register-confirm",
    name: "register-confirm",
    meta: { role: "public", title: "Registrierung bestätigen" },
    component: () => import("../views/register-confirm/Index.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    meta: { role: "public", title: "Nicht gefunden" },
    component: () => import("../views/notfound/Index.vue"),
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isProtectedRoute = to.meta.role != "public";
  let loggedIn = store.state.auth.loggedIn;

  if (!isProtectedRoute) {
    next();
    return;
  }

  if (isProtectedRoute) {
    if (!loggedIn) {
      //go to login page
      next("/login");
      return;
    } else {
      let userRole = store.state.auth.user.role;
      let routeIsAllowedFor = to.meta.role;
      let userIsAllowed = isAllowedForRoute(routeIsAllowedFor, userRole);

      if (userIsAllowed) {
        next();
        return;
      } else {
        EventBus.dispatch("logout");
        next("/login");
        return;
      }
    }
  }
  next();
});

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = "Schwarmplaner | " + to.meta.title;
  } else {
    document.title = "Schwarmplaner";
  }
});
export default router;

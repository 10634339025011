import http from "./api.js";
import errorHandler from "@/utils/errorHandler.js";

class UserService {
  emailExist(email) {
    let urlEncoded = encodeURIComponent(email);
    return http.get(`/user/exists?email=` + urlEncoded);
  }
  nameExist(name) {
    let urlEncoded = encodeURIComponent(name);
    return http.get(`/user/exists?name=` + urlEncoded);
  }
  getAllUser() {
    return http.get("/users");
  }
  getSummary() {
    return http.get(`/user/summary`);
  }

  searchInUsers(searchQuery) {
    return http
      .post("/user/search",{query: searchQuery})

  }
  async deleteUser(id) {
    return http
      .delete("/user/" + id)
      .then((response) => {
        errorHandler.handleSuccess(response);
        return true;
      })
      .catch((e) => {
        errorHandler.handleError(e);
        return false;
      });
  }
  async updateUser(user) {
    return http
      .patch("/user/" + user.id, user)
      .then((response) => {
        errorHandler.handleSuccess(response);
        return true;
      })
      .catch((e) => {
        errorHandler.handleError(e);
        return false;
      });
  }
}

export default new UserService();

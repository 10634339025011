import { createStore } from "vuex";
import { auth } from "./auth.store";
import { memory } from "./memory.store";

const store = createStore({
  modules: {
    auth,
    memory,
  },
});

export default store;

import http from "./api.js";
import errorHandler from "@/utils/errorHandler.js";
class LocationService {
  getLocations() {
    return http.get("/locations");
  }
  async deleteLocation(id) {
    return http
      .delete("/location/" + id)
      .then((response) => {
        errorHandler.handleSuccess(response);
        return true;
      })
      .catch((e) => {
        errorHandler.handleError(e);
        return false;
      });
  }
  async updateLocation(location) {
    return http
      .patch("/location/" + location.id, location)
      .then((response) => {
        errorHandler.handleSuccess(response);
        return true;
      })
      .catch((e) => {
        errorHandler.handleError(e);
        return false;
      });
  }
}

export default new LocationService();

class LocalStorageService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.accessToken;
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("user");
  }
  getShowInfoBox(key) {
    let value = localStorage.getItem(key);
    if (value === null) {
      this.setShowInfoBox(key, true);
      return this.getShowInfoBox(key);
    }
    return value === "true";
  }
  setShowInfoBox(key, value) {
    localStorage.setItem(key, value);
  }
}

export default new LocalStorageService();

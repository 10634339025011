import { roles } from "@/helpers/role.helper";
import AuthService from "@/services/auth.service";
import LocalStorageService from "@/services/localStorage.service";

export const auth = {
  namespaced: true,
  state: {
    user: {},
    loggedIn: false,
  },
  actions: {
    refreshToken({ commit }, user) {
      commit("refreshToken", user);
    },
    mount({ commit }, user) {
      commit("mount", user);
    },
    login({ commit }, user) {
      return AuthService.login(user).then(
        (response) => {
          if (response.data.data.user.accessToken) {
            LocalStorageService.setUser(response.data.data.user);
            commit("loginSuccess", response.data.data.user);
            return Promise.resolve(response);
          }
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      LocalStorageService.removeUser();
      commit("logout");
    },
  },
  getters: {
    userId(state) {
      if (state.user) return state.user.id;
      return 0;
    },
    userName(state) {
      if (state.user) return state.user.name;
      return "Nutzer";
    },
    user(state) {
      return state.user;
    },
    loggedIn(state) {
      return state.loggedIn;
    },
    isAdmin(state) {
      if (state.user && state.user["role"]) {
        return state.user["role"] === roles.admin;
      }
      return false;
    },
    isCoordinator(state) {
      if (state.user && state.user["role"]) {
        return (
          state.user["role"] === roles.coordinator &&
          state.user["role"] !== roles.admin
        );
      }
      return false;
    },
    isHelper(state) {
      if (state.user && state.user["role"]) {
        return (
          (state.user["role"] === roles.helperGuest ||
            state.user["role"] === roles.helperTicket ||
            state.user["role"] === roles.helperCrew) &&
          !state.user["role"] !== roles.coordinator &&
          !state.user["role"] !== roles.admin
        );
      }
      return false;
    },
  },
  mutations: {
    mount(state, user) {
      state.user = user;
      state.loggedIn = true;
    },
    loginSuccess(state, user) {
      state.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.loggedIn = false;
      state.user = null;
    },
    refreshToken(state, user) {
      state.loggedIn = true;
      state.user = user;
    },
  },
};

import userService from "@/services/user.service";

import { toSimpleWeekend } from "@/helpers/weekend.helper";
import errorHandler from "@/utils/errorHandler";
import locationService from "@/services/location.service";
import { roles } from "@/helpers/role.helper";
import shiftService from "@/services/shift.service";

export const memory = {
  namespaced: true,
  state: {
    users: [],
    simpleUsers: [],
    simpleCoordinators: [],
    simpleHelpers: [],
    locations: [],
    summary: {},
    userSummary: {},
  },
  actions: {
    fetchSummary({ commit, rootState }) {
      if (!rootState.auth.user) return;
      if (rootState.auth.user.role === "admin") {
        shiftService
          .getSummary()
          .then((response) => {
            commit("setSummary", response.data.data);
          })
          .catch((e) => {
            errorHandler.handleError(e);
          });
      } else {
        userService
          .getSummary()
          .then((response) => {
            commit("setSummary", response.data.data);
          })
          .catch((e) => {
            errorHandler.handleError(e);
          });
      }
    },
    fetchUser({ commit, rootState }) {
      userService
        .getAllUser()
        .then((response) => {
          commit("setUser", response.data.data.user);
          if (rootState.auth.user.role === "admin") {
            commit("setUserSummary", response.data.data.summary);
          }
        })
        .catch((e) => {
          errorHandler.handleError(e);
        });
    },
    mountLocations({ commit }) {
      locationService
        .getLocations()
        .then((response) => {
          commit("setLocations", response.data.data);
        })
        .catch((e) => {
          errorHandler.handleError(e);
        });
    },
  },
  getters: {
    users(state) {
      return state.users;
    },
    simpleUsers(state) {
      return state.simpleUsers;
    },
    simpleCoordinators(state) {
      return state.simpleCoordinators;
    },
    simpleHelpers(state) {
      return state.simpleHelpers;
    },
    locations(state) {
      return state.locations;
    },
    summary(state) {
      return state.summary;
    },
    userSummary(state) {
      return state.userSummary;
    },
  },
  mutations: {
    searchUser(state,searchValue ) {
      if(searchValue === ""){
          this.dispatch("memory/fetchUser")
          return
      }
      userService
      .searchInUsers(searchValue)
      .then((response) => {
      this.commit("memory/setUser", response.data.data.user);

      })
      .catch((e) => {
        errorHandler.handleError(e);
      });

    },
    setUser(state, users) {
      state.users = users.map((item) => {
        let temp = item;
        temp.weekends = toSimpleWeekend(item.weekendOne, item.weekendTwo);
        return temp;
      });

      state.users.forEach((item) => {
        let simpleUser = {
          title:
            item.name +
            " " +
            item.lastname +
            " " +
            item.email +
            " " +
            item.phone,
          email: item.email,
          name: item.name,
          lastname: item.lastname,
          phone: item.phone,
          id: item.id,
          role: item.role,
        };
        state.simpleUsers.push(simpleUser);
        if (item.role === roles.admin || item.role === roles.coordinator) {
          state.simpleCoordinators.push(simpleUser);
        } else {
          state.simpleHelpers.push(simpleUser);
        }
      });
    },
    setUserSummary(state, summary) {
      state.userSummary = summary;
    },
    setSummary(state, summary) {
      state.summary = summary;
    },
    setLocations(state, locations) {
      state.locations = locations.map((item) => {
        return {
          id: item.id,
          name: item.name,
          slug: item.slug,
          allowed_for_permission: item.allowed_for_permission,
        };
      });
    },
  },
};

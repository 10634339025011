export const weekends = {
  WE1: "WE1",
  WE2: "WE2",
};
export const weekendArray = [weekends.WE1, weekends.WE2];

export const weekendColors = ["red", "deep-purple"];

export function toSimpleWeekend(weekendOne, weekendTwo) {
  let result = [];
  if (weekendOne) result.push(weekends.WE1);
  if (weekendTwo) result.push(weekends.WE2);
  return result;
}

export function colorByWeekend(weekend) {
  return weekendColors[weekendArray.indexOf(weekend)];
}

import EventBus from "../helpers/event.bus";

class ErrorHandler {
  logger = {};
  init = (logger) => {
    this.logger = logger;
  };
  showToast = (message, level = "success") => {
    EventBus.dispatch("toast", { message, level });
  };

  handleError = (e) => {
    let response = e;
    if (e.data) {
      response = e.data;
    } else if (e.response) {
      response = e.response;
      if (e.response.data) {
        response = e.response.data;
      }
    } else {
      this.showToast(e.message, "error");
      this.logger.error("unhandled error");
      this.logger.error(e.message);
      return;
    }
    try {
      switch (response.status) {
        case 401:
          this.handleAuthorization(response, response.message);
          break;
        case 403:
          this.handleForbidden(response, response.message);
          break;
        case 409:
          this.handleConflict(response, response.message);
          break;
        case 500:
          this.handleInternalError(response, "Internal Error");
          break;
        default: {
          this.logger.error("unhandled error");
          this.logger.error(e.data);
          this.showToast(e, "error");
        }
      }
    } catch (error) {
      this.logger.error(error);
      this.logger.error(response);
    }
  };
  handleSuccess = (res, showToast = true) => {
    let response = res;
    if (res.data) {
      response = res.data;
    } else {
      this.showToast(response, "error");
      this.logger.error("unhandled error");
      this.logger.error(response);
      return;
    }
    this.logger.debug(response);
    if (showToast) {
      this.showToast(response.message, "success");
    }
  };

  handleConflict = (res, message, showToast = true) => {
    this.logger.debug(res);
    if (showToast) {
      this.showToast(message, "warn");
    }
  };

  handleNotFound = (res, message, showToast = true) => {
    this.logger.debug(res);
    if (showToast) {
      this.showToast(message, "warn");
    }
  };
  handleForbidden = (res, message, showToast = true) => {
    this.logger.error(res);

    if (showToast) {
      this.showToast(message, "error");
    }
  };

  handleInternalError = (res, message, showToast = true) => {
    this.logger.error(res);
    if (showToast) this.showToast(message, "error");
  };
  handleAuthorization = (res, message, showToast = true) => {
    this.logger.error(res);
    EventBus.dispatch("logout");
    if (showToast) this.showToast(message, "error");
  };
  handlePermissionError = (res, message, showToast = true) => {
    this.logger.error(res);
    EventBus.dispatch("logout");
    if (showToast) this.showToast(message, "error");
  };
}

export default new ErrorHandler();

<template>
  <div>
    <router-view></router-view>
    <ToastResponse
      ref="toastComp"
      :message="toastMessage"
      :duration="toastDuration"
    ></ToastResponse>
  </div>
</template>

<script>
import EventBus from "./helpers/event.bus";
import ErrorHandler from "@/utils/errorHandler";
import ToastResponse from "./components/custom/ToastResponse.vue";
import localStorageService from "./services/localStorage.service";

export default {
  name: "App",

  components: {
    ToastResponse,
  },
  data() {
    return {
      toastDuration: 3000,
      toastMessage: "",
    };
  },
  mounted() {
    ErrorHandler.init(this.$log);
    EventBus.on("logout", () => {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    });

    EventBus.on("toast", (message) => {
      this.toastMessage = message.message;
      this.showToast();
    });
    let user = localStorageService.getUser();
    if (user) {
      this.$store.dispatch("auth/mount", user);
    }
  },

  methods: {
    showToast() {
      this.$refs.toastComp.showToast();
    },
  },
  beforeDestroy() {
    EventBus.remove("logout");
    EventBus.remove("toast");
  },
};
</script>
